import { useState, useEffect, useContext } from "react"
import Router from "next/router"

import useTranslation from "./useTranslation"

import AppContext from "@secureo/common/context/AppContext/AppContext"

// TODO:
const useAuthentication = () => {
	const { isLoggedIn, isInitialized } = useContext(AppContext)
	const { locale } = useTranslation()
	const [showPage, setShowPage] = useState(null)

	useEffect(() => {
		if (isInitialized && showPage === null) {
			if (isLoggedIn) setShowPage(true)
			else {
				setShowPage(false)
				Router.push(`/${locale}/`)
			}
		}
	}, [isInitialized, isLoggedIn, showPage, locale])

	return showPage
}

export default useAuthentication
