import axios from "axios"

import createMyOrderFromCart from "@secureo/common/utils/commerceTools/graphql/mutations/createMyOrderFromCart"
import { authorizeKlarnaSession, createKlarnaOrder } from "../actions"

import { OrderMyCartCommand } from "@secureo/common/typings/generated/commercetools-graphql"
import { CheckoutContextState } from "../CheckoutContext"
import { PaymentMethodKey } from "@secureo/common/typings/Payment"
import { Cart } from "@secureo/common/typings/Cart"
import fetchActiveCart from "@secureo/common/utils/commerceTools/graphql/queries/fetchActiveCart"

const createCommerceToolsPayment = async (cart: Cart, selectedPaymentMethod: PaymentMethodKey) => {
	const { data: response } = (await axios.get(
		`/api/cart/createPayment?cartId=${cart.id}&selectedPaymentMethod=${selectedPaymentMethod}`,
	)) as {
		data: {
			payment: {
				id: string
			}
			updatedCart: { version: number }
		}
	}

	return response
}

const processPrepaidPayment = async (cart: Cart, accessToken: string, locale: string) => {
	const { updatedCart } = await createCommerceToolsPayment(cart, "prepaid")

	const myOrderDraft: OrderMyCartCommand = {
		id: cart.id,
		version: updatedCart.version,
	}
	await createMyOrderFromCart(accessToken, myOrderDraft)

	const prefix = process.env.NEXT_PUBLIC_COMMERCETOOLS_STORE_KEY === "TRE" ? "" : `/${locale}`

	const redirectUrl = `${prefix}/success?paymentMethod=prepaid&cartId=${cart.id}`
	return redirectUrl
}

const mpay24ApiBaseUrl = process.env.NEXT_PUBLIC_ENABLE_UNZER === "true" ? "unzer" : "mpay24"

const processMpay24Payment = async (
	cart: Cart,
	selectedPaymentMethod: PaymentMethodKey,
	locale: string,
	shippingAddressDiffers: boolean,
) => {
	const {
		payment: { id: paymentId },
	} = await createCommerceToolsPayment(cart, selectedPaymentMethod)

	// Call api route
	const { data: response } = (await axios.get(
		`/api/${mpay24ApiBaseUrl}/checkout?cartId=${
			cart.id
		}&selectedPaymentMethod=${selectedPaymentMethod}&locale=${locale}&paymentId=${paymentId}&shippingAddressDiffers=${String(
			shippingAddressDiffers,
		)}`,
	)) as {
		data: {
			status: string
			returnCode: string
			location: string
		}
	}

	const { location: redirectUrl } = response

	return redirectUrl
}

const processKlarnaPayment = async (
	cart: Cart,
	accessToken: string,
	checkoutContext: CheckoutContextState,
	host: string,
	hideKlarnaPayments: () => void,
	language = "de",
) => {
	const isApproved = await authorizeKlarnaSession(cart, checkoutContext, host)

	if (!isApproved) {
		hideKlarnaPayments()
		return null
	}

	const redirectUrl = await createKlarnaOrder(checkoutContext, cart.id)

	const updatedCart = await fetchActiveCart(accessToken, language)
	const myOrderDraft: OrderMyCartCommand = {
		id: cart.id,
		version: updatedCart.version,
	}

	await createMyOrderFromCart(accessToken, myOrderDraft)

	return redirectUrl
}

const processPayment = async (
	checkoutContext: CheckoutContextState,
	cart: Cart,
	accessToken: string,
	locale: string,
	host: string,
	hideKlarnaPayments: () => void,
): Promise<string> => {
	const { selectedPaymentMethod, shippingAddressDiffers } = checkoutContext

	switch (selectedPaymentMethod) {
		case "prepaid":
			return processPrepaidPayment(cart, accessToken, locale)

		case "creditCard":
		case "paypal":
		case "eps":
		case "applePay":
		case "przelewy24":
			return processMpay24Payment(cart, selectedPaymentMethod, locale, shippingAddressDiffers)

		case "pay_now":
		case "direct_bank_transfer":
		case "pay_later":
		case "pay_over_time":
			return processKlarnaPayment(
				cart,
				accessToken,
				checkoutContext,
				host,
				hideKlarnaPayments,
			)

		default: {
			console.error(`No payment logic defined for ${selectedPaymentMethod}`)
			alert(`No payment logic defined for ${selectedPaymentMethod}`)
			return null
		}
	}
}

export default processPayment
