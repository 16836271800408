import getRawAttributeByName from "@secureo/common/utils/commerceTools/getRawAttributeByName"

import { CountryCode } from "@secureo/common/typings/CountryCode"
import {
	RawDeliveryOptionsAttribute,
	DeliveryOptionsByDestinationCountryCode,
} from "@secureo/common/typings/Product"

const getDeliveryOptionsByDestinationCountryCode = (
	deliveryOptionsAttribute: RawDeliveryOptionsAttribute,
): DeliveryOptionsByDestinationCountryCode => {
	const { value: rawDeliveryOptions = [] } = deliveryOptionsAttribute

	const deliveryOptions = rawDeliveryOptions
		.map((rawDeliveryOption) => {
			const countryCodes = getRawAttributeByName(
				rawDeliveryOption,
				"countryCode",
			).value.split(";") as string[]

			const skus = getRawAttributeByName(rawDeliveryOption, "sku").value.split(
				";",
			) as string[]

			const netPricesEuroCents = getRawAttributeByName(
				rawDeliveryOption,
				"price",
			).value.split(";") as string[]

			const netPurchasePricesCents =
				(getRawAttributeByName(rawDeliveryOption, "shippingPurchasePrice")?.value?.split(
					";",
				) as string) ?? netPricesEuroCents

			const deliveryOptions = []

			for (let i = 0; i < countryCodes.length; i++) {
				const destinationCountryCode = countryCodes[i] as CountryCode
				const sku = skus[i]
				const netPriceEuroCents = Number(netPricesEuroCents[i])
				const netPurchasePriceEuroCents = Number(netPurchasePricesCents[i])

				const deliveryOption = {
					destinationCountryCode,
					sku,
					netPriceEuroCents,
					netPurchasePriceEuroCents,
				}

				deliveryOptions.push(deliveryOption)
			}

			return deliveryOptions
		})
		.flat()

	const destinationCountryCodes = Array.from(
		new Set(deliveryOptions.map(({ destinationCountryCode }) => destinationCountryCode)),
	) as CountryCode[]

	const deliveryOptionsByDestinationCountryCode = destinationCountryCodes.reduce(
		(deliveryOptionsByDestinationCountryCode, destinationCountryCode) => {
			const deliveryOptionsForDestinationCountryCode = deliveryOptions.filter(
				(deliveryOptions) =>
					deliveryOptions.destinationCountryCode === destinationCountryCode,
			)

			return {
				...deliveryOptionsByDestinationCountryCode,
				[destinationCountryCode]: deliveryOptionsForDestinationCountryCode,
			}
		},
		{} as DeliveryOptionsByDestinationCountryCode,
	)

	return deliveryOptionsByDestinationCountryCode
}

export default getDeliveryOptionsByDestinationCountryCode
