import { CurrencyCode } from "../typings/Forex"

export interface ShippingCosts {
	[countryCode: string]: number
}

export interface ShippingZoneCosts {
	[weightThresholdKg: string]: number
}

export interface ShippingZoneCostsByZoneNumber {
	[zoneNumber: string]: ShippingZoneCosts
}

export interface ShippingCostsByLineItemId {
	[lineItemId: string]: number
}

export const flatFeesByCurrencyCode = {
	EUR: {
		flatFeeForSmallAmountCents: 750,
		flatFeeForSmallAmountThresholdGrossCents: 7500,
		flatFeeForNonHaulageDeliveryCents: 1490,
	},
	PLN: {
		flatFeeForSmallAmountCents: 3500,
		flatFeeForSmallAmountThresholdGrossCents: 35000,
		flatFeeForNonHaulageDeliveryCents: 7500,
	},
}

// Zone 0 = Free shipping
export const shippingZones: ShippingCosts = {
	// EU
	AT: 0, // ShopCountry
	BE: 6,
	BG: 10,
	CZ: 3,
	DE: 0, // ShopCountry
	DK: 7,
	EE: 9,
	ES: 9,
	FI: 9,
	FR: 8,
	GB: 8,
	GR: 11,
	HR: 8,
	IE: 9,
	IT: 7,
	LV: 9,
	LT: 9,
	LU: 6,
	NL: 6,
	PL: 6,
	PT: 9,
	RO: 10,
	SE: 9,
	SI: 3,
	SK: 6,
	HU: 5,
	// EFTA
	CH: 0, // ShopCountry
	LI: 0, // Same as CH
	NO: 9,
}

// For PLN currency, these prices are multiplied
export const shippingCostMultiplierByCurrencyCode: Pick<
	Record<CurrencyCode, number>,
	"EUR" | "PLN"
> = {
	EUR: 1,
	PLN: 5,
}
export const shippingZoneCostsByZoneNumber: ShippingZoneCostsByZoneNumber = {
	3: {
		0: flatFeesByCurrencyCode.EUR.flatFeeForNonHaulageDeliveryCents,
		25: 4680,
		50: 7020,
		100: 10625,
		150: 12105,
		200: 14936,
		300: 20116,
		400: 25447,
		500: 30638,
		600: 33744,
		700: 37115,
		800: 40760,
		900: 44550,
		1000: 48206,
		1100: 51416,
		1200: 54206,
		1300: 56240,
		1400: 57556,
		1500: 58875,
		1600: 60336,
		1700: 63545,
		1800: 66466,
		1900: 68221,
		2000: 69245,
		2100: 70140,
		2200: 73480,
		2300: 76819,
		2400: 80160,
		2500: 83500,
		2600: 86840,
		2700: 90180,
		2800: 93520,
		Infinity: 96860,
	},
	5: {
		0: flatFeesByCurrencyCode.EUR.flatFeeForNonHaulageDeliveryCents,
		25: 5462,
		50: 8193,
		100: 12075,
		150: 13361,
		200: 16816,
		300: 23086,
		400: 28901,
		500: 35195,
		600: 38137,
		700: 42070,
		800: 46165,
		900: 50405,
		1000: 54341,
		1100: 58141,
		1200: 61358,
		1300: 63837,
		1400: 65442,
		1500: 66763,
		1600: 68660,
		1700: 72165,
		1800: 75230,
		1900: 77272,
		2000: 78295,
		2100: 79406,
		2200: 83187,
		2300: 86968,
		2400: 90750,
		2500: 94531,
		2600: 98312,
		2700: 102093,
		2800: 105875,
		Infinity: 109656,
	},
	6: {
		0: flatFeesByCurrencyCode.EUR.flatFeeForNonHaulageDeliveryCents,
		25: 5749,
		50: 8623,
		100: 13025,
		150: 14302,
		200: 17918,
		300: 24977,
		400: 31107,
		500: 37541,
		600: 40907,
		700: 45286,
		800: 49823,
		900: 54341,
		1000: 58578,
		1100: 62673,
		1200: 66025,
		1300: 68803,
		1400: 70407,
		1500: 71878,
		1600: 74066,
		1700: 77418,
		1800: 80931,
		1900: 83118,
		2000: 84140,
		2100: 85575,
		2200: 89650,
		2300: 93725,
		2400: 97800,
		2500: 101875,
		2600: 105950,
		2700: 110025,
		2800: 114100,
		Infinity: 118175,
	},
	7: {
		0: flatFeesByCurrencyCode.EUR.flatFeeForNonHaulageDeliveryCents,
		25: 6135,
		50: 9203,
		100: 13995,
		150: 15568,
		200: 19656,
		300: 26713,
		400: 33608,
		500: 40833,
		600: 44550,
		700: 48933,
		800: 53907,
		900: 58578,
		1000: 63256,
		1100: 67640,
		1200: 71288,
		1300: 74208,
		1400: 76108,
		1500: 77713,
		1600: 79611,
		1700: 83700,
		1800: 87061,
		1900: 89555,
		2000: 90718,
		2100: 92242,
		2200: 96635,
		2300: 101027,
		2400: 105420,
		2500: 109812,
		2600: 114205,
		2700: 118597,
		2800: 122990,
		Infinity: 127382,
	},
	8: {
		0: flatFeesByCurrencyCode.EUR.flatFeeForNonHaulageDeliveryCents,
		25: 6525,
		50: 9788,
		100: 14792,
		150: 16816,
		200: 21523,
		300: 29523,
		400: 37075,
		500: 44920,
		600: 48793,
		700: 53756,
		800: 59020,
		900: 64278,
		1000: 69388,
		1100: 74066,
		1200: 77866,
		1300: 81510,
		1400: 83543,
		1500: 85315,
		1600: 87497,
		1700: 91737,
		1800: 95386,
		1900: 98155,
		2000: 99183,
		2100: 100878,
		2200: 105682,
		2300: 110486,
		2400: 115290,
		2500: 120093,
		2600: 124897,
		2700: 129701,
		2800: 134505,
		Infinity: 139308,
	},
	9: {
		0: flatFeesByCurrencyCode.EUR.flatFeeForNonHaulageDeliveryCents,
		25: 8867,
		50: 13301,
		100: 19613,
		150: 22003,
		200: 28281,
		300: 38791,
		400: 48382,
		500: 58273,
		600: 62958,
		700: 69388,
		800: 75812,
		900: 82533,
		1000: 88665,
		1100: 94947,
		1200: 99921,
		1300: 104008,
		1400: 106492,
		1500: 108976,
		1600: 111600,
		1700: 116563,
		1800: 121377,
		1900: 124595,
		2000: 125768,
		2100: 127890,
		2200: 133979,
		2300: 140070,
		2400: 146160,
		2500: 152250,
		2600: 158340,
		2700: 164430,
		2800: 170520,
		Infinity: 176610,
	},
	10: {
		0: flatFeesByCurrencyCode.EUR.flatFeeForNonHaulageDeliveryCents,
		25: 10325,
		50: 15487,
		100: 22668,
		150: 25447,
		200: 32528,
		300: 44602,
		400: 55607,
		500: 66890,
		600: 72462,
		700: 79611,
		800: 87497,
		900: 94947,
		1000: 102107,
		1100: 109402,
		1200: 114806,
		1300: 119783,
		1400: 122548,
		1500: 125176,
		1600: 128392,
		1700: 134382,
		1800: 139346,
		1900: 143287,
		2000: 144748,
		2100: 146763,
		2200: 153752,
		2300: 160741,
		2400: 167730,
		2500: 174718,
		2600: 181707,
		2700: 188696,
		2800: 195685,
		Infinity: 202673,
	},
	11: {
		0: flatFeesByCurrencyCode.EUR.flatFeeForNonHaulageDeliveryCents,
		25: 10841,
		50: 16262,
		100: 23801,
		150: 26720,
		200: 34155,
		300: 46832,
		400: 58386,
		500: 70235,
		600: 76086,
		700: 83591,
		800: 91872,
		900: 99693,
		1000: 107211,
		1100: 114873,
		1200: 120546,
		1300: 125773,
		1400: 128677,
		1500: 131433,
		1600: 134810,
		1700: 141101,
		1800: 146313,
		1900: 150452,
		2000: 151986,
		2100: 154087,
		2200: 161425,
		2300: 168762,
		2400: 176100,
		2500: 183437,
		2600: 190775,
		2700: 198112,
		2800: 205450,
		Infinity: 212787,
	},
}
