import Router from "next/router"
import getPricePortions from "../utils/getPricePortions"
import getMsrpPricePortions from "../utils/getMsrpPricePortions"
import getMergedAttributes from "../utils/getMergedAttributes"
import validateConfigurations from "../utils/configurationGroups/validateConfigurations"

import { DispatchProductDetailPageReducer, ProductDetailPageReducerState } from ".."

interface SetVariantPayload
	extends Pick<
		ProductDetailPageReducerState,
		| "variantId"
		// General
		| "attributes"
		| "configurationsByConfigurationGroup"
		// Price
		| "singleNetPriceCents"
		| "singleGrossPriceCents"
		| "totalNetPriceCents"
		| "totalGrossPriceCents"
		| "pricePortions"
		| "singleNetMsrpPriceCents"
		| "singleGrossMsrpPriceCents"
		| "totalNetMsrpPriceCents"
		| "totalGrossMsrpPriceCents"
		| "msrpPricePortions"
	> {
	images?: ProductDetailPageReducerState["images"]
	assets?: ProductDetailPageReducerState["assets"]
}

export const setVariant = (
	dispatch: DispatchProductDetailPageReducer,
	reducerState: ProductDetailPageReducerState,
	variantId: number,
) => {
	const {
		countryCode,
		variantsByVariantId,
		slug,
		locale,
		isDebug,
		productType,
		shopKey,

		// General
		configurationsByConfigurationGroup: prevConfigurationsByConfigurationGroup,

		// Price
		quantity,
		taxRate,
	} = reducerState

	const variant = variantsByVariantId?.[variantId]
	const {
		images = [],
		assets = [],
		attributes: {
			variantsHaveUniqueProductPage = false,
			configurationGroups: newConfigurationGroups = [],
		},
	} = variant

	// Validate configurations
	const validPrevConfigurations = validateConfigurations(
		prevConfigurationsByConfigurationGroup,
		newConfigurationGroups,
	)

	const {
		singleNetPriceCents,
		singleGrossPriceCents,
		totalNetPriceCents,
		totalGrossPriceCents,
		pricePortions,
	} = getPricePortions(
		variantsByVariantId,
		variantId,
		taxRate,
		quantity,
		countryCode,
		validPrevConfigurations,
		null,
		null,
		null,
	)

	const attributes = getMergedAttributes(variantsByVariantId, variantId)

	const {
		singleNetMsrpPriceCents,
		singleGrossMsrpPriceCents,
		totalNetMsrpPriceCents,
		totalGrossMsrpPriceCents,
		msrpPricePortions,
	} = getMsrpPricePortions(
		quantity,
		taxRate,
		countryCode,
		attributes,
		validPrevConfigurations,
		pricePortions.cylinderLockingMechanismProductSingleNetPriceCents,
		pricePortions.cylinderLockingMechanismProductSingleGrossPriceCents,
	)

	const payload: SetVariantPayload = {
		variantId,

		// General
		attributes,
		configurationsByConfigurationGroup: validPrevConfigurations,

		// Price
		singleNetPriceCents,
		singleGrossPriceCents,
		totalNetPriceCents,
		totalGrossPriceCents,
		pricePortions,
		singleNetMsrpPriceCents,
		singleGrossMsrpPriceCents,
		totalNetMsrpPriceCents,
		totalGrossMsrpPriceCents,
		msrpPricePortions,
	}

	if (variantsHaveUniqueProductPage) {
		if (images.length > 0) payload.images = images
		if (assets.length > 0) payload.assets = assets
	}

	dispatch({
		type: "SET_VARIANT",
		payload,
	})

	// Save variant in query param
	if (productType !== "Zylinder") {
		switch (shopKey) {
			case "TRE": {
				Router.push(
					"/p/[slug]",
					`/p/${slug}?variant=${variant.sku}${isDebug ? `&debug` : ""}`,
					{
						shallow: true,
					},
				)
				break
			}
			case "DZN": {
				Router.push(
					"/[locale]/p/[slug]",
					`/${locale}/p/${slug}?variant=${variant.sku}${isDebug ? `&debug` : ""}`,
					{
						shallow: true,
					},
				)
				break
			}
		}
	}
}
